/** @jsx jsx */
import { jsx, Box, Flex, Grid } from "theme-ui";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import SlideToggle from "react-slide-toggle";
import { eventCallbackTriggers } from "../../templates/wppage";
import HFNlogo from "../../../static/imgs/donations-logo.png";
import SMSFLogo from "../../../static/imgs/smsf-logo.png";
import Seo from "../../components/seo";

const corpusFundPageContent = (id, modalTitle) => {
  const titletext = modalTitle || "Donation for Corpus Fund";
  return `<gatsby_donation donationId = "${id}" btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Donation for Corpus Fund" allowOverseas = false/>`;
};

const generalFundPageContent = (id, modalTitle) => {
  const titletext = modalTitle || "Heartfulness General Fund";
  return `<gatsby_donation donationId="${id}" btntext="${titletext}"
  btnBg=#4d8f15 colortext = navy colorprimary = #085788
  colorsecondary = midnightblue colorbackground = white
  colormuted = #f6f6f6 colorhighlight = #efeffe titletext =
  "Heartfulness General Fund" />`;
};

const kanhaBuildingPageContent = (id, modalTitle) => {
  const titletext = modalTitle || "Donation for Green Kanha";
  return `<gatsby_donation donationId = "${id}" btntext = "${titletext}"  btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = " Donation for Kanha Building" allowOverseas = false/>`;
};
const PageContent = React.memo(PageContentNonMemoized);

const SmsfIndiaDonation = () => {
  const headerRef = useRef();

  const [applySticky, setApplySticky] = useState(false);

  const handleHeaderSticky = () => {
    if (headerRef.current && window.pageYOffset > headerRef.current.offsetTop)
      setApplySticky(true);
    else setApplySticky(false);
  };

  useEffect(
    () => window.addEventListener("scroll", handleHeaderSticky),
    [applySticky]
  );

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Donation_for_smsf_india_donation", event),
    []
  );

  const navBarLinks = [
    { title: "ABOUT SMSF", id: "about-smsf" },
    { title: "RETREAT CENTRES", id: "retreat-centres" },
    { title: "CREST", id: "crest" },
    { title: "MEDICAL CENTRES", id: "medical-centres" },
    { title: "Donate", className: "donate-btn" },
  ];

  const NavigateRouter = (item) => {
    let message = {
      title: item.title,
      url: `/${item.id}`,
    };
    if (item.id === "index") {
      message = {
        title: item.title,
        url: "/",
      };
      window.parent.postMessage(message, "*");
    }
    window.parent.postMessage(message, "*");
  };

  return (
    <>
      <Box
        ref={headerRef}
        sx={
          applySticky
            ? {
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                background: "#fff",
                zIndex: 1000,
                boxShadow: "0 2px 6px rgb(0 0 0 / 5%)",
              }
            : {
                position: "static",
                boxShadow: "0 2px 6px rgb(0 0 0 / 5%)",
              }
        }
      >
        <SlideToggle
          collapsed="true"
          render={({ toggle, setCollapsibleElement }) => (
            <div className="header-collapsible">
              <div
                sx={{
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  boxShadow: "0px 0px 2px 1px #00000029",
                  logo: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    img: {
                      maxWidth: "300px",
                    },
                  },
                  nav: {
                    flexGrow: "2",
                    ul: {
                      display: "flex",
                      padding: 0,
                      justifyContent: "flex-end",
                      listStyle: "none",
                      li: {
                        a: {
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: "18px",
                          textDecoration: "none",
                          textTransform: "capitalize",
                        },
                      },

                      "@media screen and (max-width: 998px)": {
                        display: "none !important",
                      },
                    },
                    mobNavIcon: {
                      display: "none",
                      "@media screen and (max-width: 998px)": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        position: "relative",
                        top: "14px",
                        fontSize: "23px",
                        color: "#bbbbbb",
                      },
                    },
                  },
                }}
              >
                <div
                  sx={{
                    width: "100%",
                    maxWidth: "1160px",
                    margin: "0 auto",
                    "@media only screen and (max-width: 767px)": {
                      width: "300px",
                    },
                    "@media only screen and (min-width: 480px) and (max-width: 767px)":
                      {
                        width: "420px",
                      },
                    "@media only screen and (min-width: 768px) and (max-width: 959px)":
                      {
                        width: "748px",
                      },
                    "@media only screen and (min-width: 960px) and (max-width: 1199px)":
                      {
                        width: "920px",
                      },
                    "@media only screen and (min-width: 1200px) and (max-width: 1400px)":
                      {
                        width: "1160px",
                      },
                  }}
                >
                  <div
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        img: {
                          maxWidth: "300px",
                        },
                      }}
                    >
                      <span to="/">
                        <img
                          src={SMSFLogo}
                          sx={{ width: applySticky ? "200px" : "250px" }}
                          alt=""
                        />
                      </span>
                    </div>
                    <nav>
                      <ul sx={{ marginTop: "15px" }}>
                        {navBarLinks.map((item) => {
                          if (item.id) {
                            return (
                              <li>
                                <span
                                  className={item.className || "menu-links"}
                                  onClick={() => NavigateRouter(item)}
                                  onKeyDown={() => NavigateRouter(item)}
                                  tabIndex={0}
                                  role="button"
                                  id={item.id}
                                  activeStyle={{
                                    color: "#179bb2",
                                  }}
                                  sx={{
                                    color: "#777777",
                                    ":hover": { color: "#179bb2" },
                                    padding: "0px 20px",
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    fontWeight: "300",
                                  }}
                                >
                                  {item.title}
                                </span>
                              </li>
                            );
                          }
                          return (
                            <li>
                              <span
                                className={item.className || "menu-links"}
                                activeStyle={{
                                  color: "#179bb2",
                                }}
                                sx={{
                                  ...(!item.className && {
                                    color: "#777777",
                                    ":hover": { color: "#179bb2" },
                                    padding: "0px 20px",
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    fontWeight: "300",
                                  }),
                                  ...(item.className && {
                                    padding: " 8px 15px",
                                    borderRadius: "4px",
                                    backgroundColor: "#288bd0",
                                    color: "white",
                                    opacity: "0.6",
                                    pointerEvents: "none",
                                  }),
                                }}
                              >
                                {item.title}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                      <div
                        sx={{
                          display: "none",
                          "@media screen and (max-width: 998px)": {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            position: "relative",
                            top: "14px",
                            fontSize: "23px",
                            color: "#bbbbbb",
                          },
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faBars}
                          size="1x"
                          onClick={toggle}
                        />
                      </div>
                    </nav>
                  </div>
                </div>
              </div>

              <div
                sx={{
                  display: "none",
                  background: "#fcfcfc",
                  nav: {
                    ul: {
                      listStyle: "none",
                      margin: "16px 0 30px 0",
                      padding: "0",
                      li: {
                        a: {
                          fontSize: "13px",
                          display: "block",
                          borderBottom: "1px solid #f2f2f2",
                          padding: "12px 0 !important",
                          textDecoration: "none",
                          textTransform: "capitalize",
                        },
                      },
                    },
                  },
                  "@media screen and (max-width: 998px)": {
                    display: "block",
                  },
                }}
                ref={setCollapsibleElement}
              >
                <nav
                  sx={{
                    width: "100%",
                    maxWidth: "1160px",
                    margin: "0 auto",
                    "@media only screen and (max-width: 767px)": {
                      width: "300px",
                    },
                    "@media only screen and (min-width: 480px) and (max-width: 767px)":
                      {
                        width: "420px",
                      },
                    "@media only screen and (min-width: 768px) and (max-width: 959px)":
                      {
                        width: "748px",
                      },
                    "@media only screen and (min-width: 960px) and (max-width: 1199px)":
                      {
                        width: "920px",
                      },
                    "@media only screen and (min-width: 1200px) and (max-width: 1400px)":
                      {
                        width: "1160px",
                      },
                  }}
                >
                  <ul sx={{ listStyleType: "none", float: "right" }}>
                    {navBarLinks.map((item) => {
                      if (item.id) {
                        return (
                          <li
                            key={item.id}
                            sx={{
                              marginBottom: "10px",
                              borderBottom: "1px solid #f2f2f2",
                            }}
                          >
                            <span
                              onClick={() => NavigateRouter(item)}
                              onKeyDown={() => NavigateRouter(item)}
                              tabIndex={0}
                              role="button"
                              id={item.id}
                              activeStyle={{
                                color: "#179bb2",
                              }}
                              sx={{
                                color: "#777777",
                                ":hover": { color: "#179bb2" },
                                padding: "0px 20px",
                                cursor: "pointer",
                                fontSize: "20px",
                                fontWeight: "300",
                              }}
                            >
                              {item.title}
                            </span>
                          </li>
                        );
                      }
                      return (
                        <li>
                          <span
                            className={item.className || "menu-links"}
                            activeStyle={{
                              color: "#179bb2",
                            }}
                            sx={{
                              ...(!item.className && {
                                color: "#777777",
                                ":hover": { color: "#179bb2" },
                                padding: "0px 20px",
                                cursor: "pointer",
                                fontSize: "20px",
                                fontWeight: "300",
                              }),
                              ...(item.className && {
                                padding: " 8px 15px",
                                borderRadius: "4px",
                                backgroundColor: "#288bd0",
                                color: "white",
                                opacity: "0.6",
                                pointerEvents: "none",
                              }),
                            }}
                          >
                            {item.title}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </div>
          )}
        />
      </Box>
      <Seo title="SMSF India Donations" />
      <div>
        <Grid
          sx={{
            fontWeight: "300",
          }}
        >
          <Box>
            <div
              sx={{
                padding: "35px 0px",
                backgroundColor: "#dff2f6",
                border: "1px solid #d3d5d2",
                marginBottom: "30px",
              }}
            >
              <div className="container">
                <h2
                  className="text-center"
                  sx={{
                    fontSize: "24px ",
                    fontWeight: "300",
                    color: "#43679c",
                  }}
                >
                  Sahaj Marg Spirituality Foundation, India - Donations
                </h2>
              </div>
            </div>
          </Box>
          <Box
            sx={{
              paddingRight: "35px",
              paddingLeft: "35px",
              fontSize: "17px ",
            }}
          >
            <Flex
              sx={{
                flexDirection: ["column", null, null, "row", null],
              }}
            >
              <Box sx={{ flex: ["2", null, null, "2", null] }}>
                <p sx={{ lineHeight: "34px" }}>
                  <b>Welcome!</b> Thank you for choosing to donate to Sahaj Marg
                  Spirituality Foundation, India.
                </p>
                <p>
                  Sahaj Marg Spirituality Foundation supports all the events and
                  programs undertaken by <b>Hearfulness</b>.
                </p>
              </Box>
              <Box sx={{ flex: ["1", null, null, "1", null] }}>
                <img src={HFNlogo} alt="hfnlogo" width="300px" />
              </Box>
            </Flex>

            <p sx={{ lineHeight: "34px" }}>
              On completion of the donation, a confirmation email will be sent
              to you with a completed donation form attached.{" "}
              <b>
                {" "}
                Please forward this mail back to{" "}
                <a
                  href="mailto:info.accounts@sahajmarg.org"
                  sx={{ color: "#4b77a1", textDecoration: "none" }}
                >
                  {" "}
                  info.accounts@sahajmarg.org{" "}
                </a>{" "}
                along with its attachment, as your confirmation of the donation.
              </b>
            </p>
            <p>
              <b>There are no refunds for donations.</b>
            </p>
            <div
              sx={{
                color: "#31708f",
                backgroundColor: "#d9edf7",
                borderColor: "#bce8f1",
                padding: "15px 15px 2px 15px",
                marginBottom: "20px",
                border: "1px solid transparent",
                borderRadius: "4px",
              }}
            >
              <p sx={{}}>Please select one of the following funds.</p>
            </div>

            <Box
              className="donation-container"
              sx={{
                border: "1px solid #ddd",
                marginBottom: "35px",
              }}
            >
              <Box
                sx={{
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                <b>Donation for SMSF India - Corpus Fund</b>
                <br />
                <span>
                  Donation to Corpus Fund would form a part of the capital of
                  the Organization.
                </span>
                <div
                  className="donation-btn"
                  sx={{
                    display: "flex",
                  }}
                >
                  <PageContent
                    eventCallback={eventCallback}
                    pageContent={corpusFundPageContent(1, "Contribute Now")}
                  />
                </div>
              </Box>

              <Box
                sx={{
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                <b>Donation for SMSF India - General Fund</b>
                <br />
                <span>
                  Donation to General Fund would be used for the objectives of
                  the Organization and Heartfulness.
                </span>
                <div
                  sx={{
                    display: "flex",
                  }}
                >
                  <PageContent
                    eventCallback={eventCallback}
                    pageContent={generalFundPageContent(2, "Contribute Now")}
                  />
                </div>
              </Box>

              <Box
                sx={{
                  padding: "8px",
                }}
              >
                <b>Donation for SMSF India - Kanha Building Fund</b>
                <br />
                <span>
                  Donation to this fund would be used specifically for the
                  construction works at Kanha, Hyderabad.
                </span>
                <div
                  sx={{
                    display: "flex",
                  }}
                >
                  <PageContent
                    eventCallback={eventCallback}
                    pageContent={kanhaBuildingPageContent(3, "Contribute Now")}
                  />
                </div>
              </Box>
            </Box>
          </Box>
        </Grid>
      </div>
      <div
        sx={{
          padding: "0px",
          background: "#e8f2f9",
          height: "73px",
          bottom: "0",
          width: "100%",
        }}
      >
        <div
          sx={{
            padding: "10px 0px",
            background: "#e8f2f9",
          }}
        >
          <div
            sx={{
              marginTop: "19px",
              color: "#43679c",
              fontSize: "16px",
            }}
          >
            <p
              sx={{
                lineHeight: "24px",
                fontSize: "16px",
                fontWeight: "300",
                textAlign: "center",
              }}
            >
              {" "}
              © Copyright 2023 Sahaj Marg Spirituality Foundation, All Rights
              Reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmsfIndiaDonation;
